import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const DeShedding = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Bath & De-Shedding (Undercoat Removal) Service | KK Dog Groomer</title>
        <meta name="description" content="De-Shedding removes your dog’s undercoat that is trapped under their top layer of hair." />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Bath & De-Shedding (Undercoat Removal) Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>De-Shedding removes your dog’s undercoat that is trapped under their top layer of hair. We highly recommend this service to all double coated dog breeds. By removing the undercoat, your dog will find it much easier to self-regulate their body temperature, resulting in their skin being healthier, odours will be minimised and so much more!</p>
            <p>Bath & De-Shedding / Undercoat Removal includes:</p>
          </div>
          <div className='content-txt'>
            <li>Pre-groom health check.</li>
            <li>Bath shampoo washes, followed by a conditioning treatment specific to coat & skin type.</li>
            <li>Full undercoat de-shedding brush-out.</li>
            <li>Blow dry and brush out removing dead hair and knots.</li>
            <li>Deodorising dog perfume / cologne.</li>
            <li>You can expect this treatment to reduce the shedding by up to 80% after the first treatment and we recommend your dog attends every four to six weeks (depending on coat).</li>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeShedding;
