// src/views/ServicePage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header.js';
import Footer from './Footer.js';
import Content from './contents/ServicesContent.js';

const ServicesPage = () => {

    return (
        <div className="kkdg-servicespage">
            <Helmet>
                <title>Grooming Service Offered Milton Keynes | KK Dog Groomer</title>
                <meta name="description" content="KK Dog Grooming - List of our Dog Grooming Service in Milton Keynes" />
                <script src="https://kit.fontawesome.com/7ff6e2be82.js" crossorigin="anonymous"></script>
            </Helmet>
            <Header />
            <Content /> 
            <Footer />
        </div>
    );
};

export default ServicesPage;
