// src/views/homepage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import './Homepage.css';
import TopFold from './TopFold.js';
import Content from './contents/HomeContent.js';
import Footer from './Footer.js'

const HomePage = () => {

    return (
        <div className="kkdg-homepage">
            <Helmet>
                <title>Dog Grooming Service Milton Keynes | KK Dog Groomer</title>
                <meta name="description" content="KK Dog Groomer - Professional and friendly Dog Grooming Service in Milton Keynes" />
                <script src="https://kit.fontawesome.com/7ff6e2be82.js" crossorigin="anonymous"></script>
            </Helmet>
            <TopFold />
            <Content />
            <Footer />
        </div>
    );
};

export default HomePage;
