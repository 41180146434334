import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const HandStripping = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Hand Stripping Service | KK Dog Groomer</title>
        <meta name="description" content="A grooming method for removing excess hair from dog’s coat from the root, rather than just the top layer as clipping would, enable new coat to grow." />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Hand Stripping Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>In order to maintain the breed standard quality of some dogs coat types is recommend hand stripping over clipping with electric clippers.</p>
            <p>This is a type of grooming method to remove the excess hair from the dog’s coat. Hand stripping is done by hand and removes the coat from the root rather than just the top layer, as clipping would. This creates room to enable a new coat to grow. In some dog breeds this style of grooming will keep the coat in a brighter condition.</p>
            <p>The type of coat that will benefit from hand stripping produces two types of hair – the undercoat which is soft and dense while the top coat is longer and wiry in texture. Dogs with wiry coats go through a specific growing cycle in which the hair becomes thicker and darker as it grows – if you do not remove the dead hair from the top coat it will have a negative impact on the overall appearance of the coat.</p>
            <p>Dogs that may benefit from hand stripping are:
              <ul>Border Terrier</ul>
              <ul>Cairn Terrier</ul>
              <ul>Norwich Terrier</ul>
              <ul>Wire Fox Terrier</ul>
              <ul>Schnauzer</ul>
              <ul>German Wirehaired Pointer</ul>
              <ul>Irish Wolfhound</ul>
              <ul>Scottish Terrier</ul>
              <ul>Affenpinscher</ul>
            </p>
          </div>

          <div className='content-txt'>
            <p><b>Hand stripping includes</b>:</p>
            <li>Full body Hand stripping or Hand stripped jacket/thinners skirts legs (follow breed standard)</li>
            <li>Pre-groom style consultation and health check.</li>
            <li>Bath can be given if the strip was not excessive</li>
            <li>Blow / Fluff dry and brush out removing dead hair and knots for Hand stripped jacket/thinners skirts legs</li>
            <li>Face / Hygiene trim.</li>
            <li>Pads & paws trim</li>
            <li>Nails clipped</li>
            <li>Eyes & ears cleanse (plucking only if needed)KK Dog Grooming Services</li>
            <li>Deodorising dog perfume / cologne.</li>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HandStripping;
