import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const FullGrooming = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Full Dog Grooming Service | KK Dog Groomer</title>
        <meta name="description" content="KK Dog Grooming Full Grooming Service offers a comprehensive grooming experience for your furry friend." />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Full Dog Grooming Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>Our full dog grooming service offers everything your dog needs to feel healthy and look great. The deepest clean, a full body style, and all the necessary trimming and stripping to get your dog looking and smelling their absolute best.</p>
            <p>Every dog is unique. The condition of the coat, style and the dog’s behaviour all factor in the final price.</p>
            <p>KK Dog Grooming will work to customise your groom for you and your dog. We will provide you with a consultation and estimate prior to the groom.</p>
          </div>
          <div className='content-txt'>
            <li>Pre-groom style consultation and health check.</li>
            <li>2 Hydrobath shampoo washes and conditioning treatment specific to coat & skin type.</li>
            <li>Blow / Fluff dry and brush out removing dead hair and knots.</li>
            <li>Full body styling clipped or styled to owner’s request.</li>
            <li>Hygiene trim.</li>
            <li>Nails clipped.</li>
            <li>Pads & paws trim.</li>
            <li>Eyes & ears cleanse (plucking only if needed).</li>
            <li>Deodorising dog perfume / cologne.</li>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FullGrooming;
