import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const BathBlowDry = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Bath, Blow Dry & Brush Service | KK Dog Groomer</title>
        <meta name="description" content="Bath, Blow Dry & Brush is ideal for short hair dogs as it helps to remove shedding and keep coats clean and shiny." />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Bath & Blow Dry Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>Bath & Blow Dry is ideal for short hair dogs as it helps to remove shedding and keep coats clean and shiny.</p>
            <p>Bath & Blow Dry includes:</p>
          </div>
          <div className='content-txt'>
            <li>Pre-groom health check.</li>
            <li>Dubble shampoo washes, followed by a conditioning treatment specific to coat & skin type.</li>
            <li>Blow dry to removing dead hair.</li>
            <li>Deodorising dog perfume/cologne.</li>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BathBlowDry;
