import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <header className="footer">
      <div className="footer-content">
        <div className="copyright">
          <span>© {currentYear} KK Dog Grooming  All Rights Reserved</span>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/FluffyGroomAtHome" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Footer;
