import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const MaintenanceTrim = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Bath, Brush & Trim Service | KK Dog Groomer</title>
        <meta name="description" content="Describe the page content..." />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Bath, Brush & Maintenance Trim Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>Content goes here...</p>
            <p>This service includes:</p>
          </div>
          <div className='content-txt'>
            <li>List of what is included goes here</li>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MaintenanceTrim;
