import React from 'react';
import './HomeContent.css';

const HomeContent = () => {
  return (
    <div className="home-content">
        <h1>Welcome to KK Dog Grooming</h1>
        <p><b>Dog Grooming isn't just a business</b>, I am lucky that my job is my passion.</p>
        <div>
            <p>Hello! My name is Kristine and I'm the proude owner of KK Dog Grooming.</p>
            <p>I'm a dog Groomer and a dog show handler, and had been a Bernese Mountain dog breeder.<br />
                For over 15 years I've worked with dogs of different breads, coats types, dog characters and behaviours.</p>
            <p>I provide one to one dog grooming service in Milton Keynes, helping to <b>reduce stress for your dog</b>.</p>
            <p>We give your dog the undivided attention it needs.</p>
            <p>Book your appointment today.</p>
            <div>
                <a href="tel:+447415505151" id="telephone"> 
                    <div> 
                        <button className='telephone'>Call 07415 505151</button> 
                    </div> 
                </a>
            </div>
            <p>I will turn your little beast into a beauty.</p>
        </div>
    </div>
  );
};

export default HomeContent;
