import React from 'react';
import './ServicesContent.css';
import { Link } from 'react-router-dom';

const services = [
    {
      title: 'Full Grooming Service',
      img: '/images/services/full-grooming.jpg',
      uri: '/services/full-grooming', // Updated relative path
    },
    {
      title: 'Bath & Blow Dry',
      img: '/images/services/bath-blow-dry.jpg',
      uri: '/services/bath-blow-dry', // Updated relative path
    },
    {
      title: 'Puppy Introduction',
      img: '/images/services/puppy-introduction.jpg',
      uri: '/services/puppy-introduction', // Updated relative path
    },
    {
      title: 'Bath & De-Shedding/Undercoat Removal',
      img: '/images/services/de-shedding.jpg',
      uri: '/services/de-shedding', // Updated relative path
    },
    /*{
      title: 'Bath, Brush & Maintenance Trim',
      img: '/images/services/maintenance-trim.jpg',
      uri: '/services/maintenance-trim', // Updated relative path
    },*/
    {
      title: 'Bath & Hygiene Tidy',
      img: '/images/services/hygiene-tidy.jpg',
      uri: '/services/bath-hygiene-tidy', // Updated relative path
    },
    {
      title: 'Hand Stripping',
      img: '/images/services/hand-stripping.jpg',
      uri: '/services/hand-stripping', // Updated relative path
    },
  ];

const ServicesContent = () => {
  return (
    <div className="services-content">
      <h1>Grooming Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <Link to={service.uri} className="service-link">
              <img src={service.img} alt={service.title} className="service-image" />
              <p className="service-title">{service.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesContent;
