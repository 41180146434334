import React from 'react';
import './ContactContent.css';

const ContactContent = () => {
  return (
    <div className="contact-content">
        <h1>To Book a Grooming Appointment</h1>
        <p>Or have your questions or concerns answered call <button className='telephone'><a href="tel:+447415505151" id="telephone"><span>07415 505151</span></a></button></p>
        
        <div>
            <p>We are located at: <span className='address'>30 Wharfside, Fenny Stratford, Milton Keynes MK2 2AZ</span></p>
            <div className='g-map'>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2456.537623781329!2d-0.7179803875999998!3d51.99708067469587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487654e0b4e9e709%3A0x3f1941011640d857!2s30%20Wharfside%2C%20Fenny%20Stratford%2C%20Bletchley%2C%20Milton%20Keynes%20MK2%202AZ%2C%20UK!5e0!3m2!1sen!2sus!4v1733103094421!5m2!1sen!2sus" 
                    title='KK Dog Grooming Salon'
                    width="600" 
                    height="450" 
                    style={{ border:0 }}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    </div>
  );
};

export default ContactContent;
