// src/views/NotFound.js
import React from 'react';
import {Link} from 'react-router-dom';
import Header from './Header.js';
import Footer from './Footer.js';

const NotFound = () => (
    <div style={{textAlign: 'center', padding: '50px'}}>
        <Header />
            <h1>404 - Page Not Found</h1>
            <p>The page you're looking for doesn't exist.</p>
            <Link to="/">Go back to the homepage</Link>
        <Footer /> 
    </div>
);

export default NotFound;
