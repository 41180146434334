import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const PuppyIntroduction = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Puppy Introduction Service | KK Dog Groomer</title>
        <meta name="description" content="A gentle introduction to grooming for puppies from 8 weeks to 4 months of age. A chance for your puppy to be familiarised with the grooming environment and equipment, wich reduces anxiety as they mature" />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Puppy Introduction Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>This is a gentle introduction to grooming for puppies from 8 weeks to 4 months of age. It’s very important that you introduce your little one to grooming from a very young age. Puppy haircut.</p>
            <p>It is a chance for your puppy to be familiarised with the grooming environment and equipment.</p>
            <p>Your puppy will be given as much time as needed to allow him or her to settle.</p>
            <p>Lots of cuddles and play time included!</p>
            <p>Puppy Introduction includes:</p>
          </div>
          <div className='content-txt'>
            <li>A warm bath.</li>
            <li>Gentle blow / Fluffy dry.</li>
            <li>Brush (allowing your puppy to learn to get used to their face and paws being handled).</li>
            <li>Blow dry and brush out removing dead hair and knots.</li>
            <li>Nail clipping is also included with all of our puppy packages, because this procedure can often be a cause for anxiety in dogs. By exposing them to this at a young age it will reduce this anxiety for them in the future.</li>
          </div>
          <div className='content-txt'>
            <p>This service also includes a face and bottom trim – and not forgetting a play with me…</p>
            <p>*<b>All puppies must have their vaccination cards completed</b>.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PuppyIntroduction;
