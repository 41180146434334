// src/views/ServicePage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import './ContactPage.css';
import Header from './Header.js';
import Content from './contents/ContactContent.js';
import Footer from './Footer.js';
import './TopFold.js';

const ContactPage = () => {

    return (
        <div className="kkdg-contactpage">
            <Helmet>
                <title>KK Dog Grooming Contact Page | KK Dog Groomer</title>
                <meta name="description" content="We are located at 30 Wharfside, Fenny Stratford, Milton Keynes MK2 2AZ" />
            </Helmet>
            <Header />
            <Content />
            <Footer /> 
        </div>
    );
};

export default ContactPage;
