import React from 'react';
import { Helmet } from 'react-helmet';
import './service.css';
import Header from '../../Header.js';
import Footer from '../../Footer.js';

const BathHygienTidy = () => {
  return (
    <div className='servicePage'>
      <Helmet>
        <title>Bath, Brush & Hygiene Tidy Service | KK Dog Groomer</title>
        <meta name="description" content="The Bath & Hygiene Tidy helps to minimise the risk of bacterial infection to your dog. Used as an interim groom, it helps to remove shedding and keeps the coat clean and helps to stay on top of matting." />
      </Helmet>
      <Header />
      <div className="service-container">
        <h1>Bath, Brush & Hygiene Tidy Service</h1>
        <div className="service-content">
          <div className='content-txt'>
            <p>Bath, Brush & Hygiene Tidy Service is ideal for dogs with long fur, particularly those dogs that are in-between full grooms or for the naughty ones who like to roll in mud (or worse fox poo).</p>
            <p>If used as an interim groom, it helps to remove shedding hair and keep their coats clean, which in turn helps reduce the risk of matting.</p>
          </div>

          <div className='content-txt'>
            <p><b>Bath, Brush & Hygiene Tidy includes</b>:</p>
            <li>Pre-groom health check.</li>
            <li>Dubble shampoo washes, followed by a conditioning treatment specific to coat & skin type.</li>
            <li>Blow dry and brush out, removing dead hair and knots.</li>
            <li>Deodorising dog perfume / cologne.</li>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BathHygienTidy;
